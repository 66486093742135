<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="27" :md="15" :lg="{span: 15, offset: 0}" :xl="{span: 9, offset: 2}" class="col-form">
        <a-card :bordered="false" style="background-color: #FAFAFA;
    border: 1px solid #F5F5F5;border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
    overflow: hidden;padding: 30px">

        <h1 class="mb-15">Sign In</h1>
				<h5 class="font-regular text-muted">Enter your email and password to sign in</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
          <a-form-item class="mb-5" :colon="false">
<!--            <span style="color: red"-->
<!--            ><b>{{ error_msg }}</b></span-->
<!--            >-->
            <a-alert v-if="error_msg" :message="error_msg" type="error" show-icon />

          </a-form-item>
          <!--					<a-form-item class="mb-10">-->
<!--    					<a-switch v-model="rememberMe" /> Remember Me-->
<!--					</a-form-item>-->
					<a-form-item>
						<a-button type="primary" :loading="loading" block html-type="submit" class="login-form-button">
							SIGN IN
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

<!--				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p>-->
        </a-card>

      </a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
        loading:false,
        error_msg:''
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
        this.error_msg=''
        this.form.validateFields((err, values) => {
					if ( !err ) {
            let form={email:values.email,password:values.password}
            this.loading=true
            this.$store.dispatch("loginAttempt", form)
                .then((res) => {
                  // if (responseData !== 400)
                    this.$router.push({ name: "Dashboard" });
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                  this.error_msg=err.response.data.message
                })
                .finally(() => {
                  this.loading = false
                });
					}
				});
			},
		},
	})

</script>

<!--<style lang="scss">-->
<!--	body {-->
<!--		background-color: #ffffff;-->
<!--	}-->
<!--</style>-->